.quote {
  margin: 0;
  padding: 1em;
  border-radius: 1em;
  background-color: #050505;
  hyphens: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}
.quote figcaption,
.quote blockquote {
  margin: 1em;
}

div.swiper-button-prev,
div.swiper-button-next {
  color: white;
}

span.swiper-pagination-bullet {
  background-color: white;
}
